var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c("header", { staticClass: "app-welcome-message d-flex" }, [
        !_setup.isAddingMasterMode && !_setup.isAddingDetailMode
          ? _c("h2", [_vm._v("Details van koppeling meter met locatie")])
          : _setup.isAddingMasterMode
          ? _c("h2", [_vm._v("Toevoegen van koppeling meter met locatie")])
          : _setup.isAddingDetailMode
          ? _c("h2", [
              _vm._v("Toevoegen van koppeling meter met locatie versie"),
            ])
          : _vm._e(),
        _c("h2", { class: { loadingDots: !_setup.allDataLoaded } }),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _setup.hasDetails
                        ? _c("v-col", { attrs: { cols: "auto" } }, [
                            _vm._v(" Tijdlijn: "),
                          ])
                        : _vm._e(),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn-toggle",
                            {
                              attrs: {
                                group: "",
                                mandatory: "",
                                color: "blue accent-3",
                              },
                              model: {
                                value: _setup.selectedTabIndex,
                                callback: function ($$v) {
                                  _setup.selectedTabIndex = $$v
                                },
                                expression: "selectedTabIndex",
                              },
                            },
                            _vm._l(_setup.detailTabs(), function (item, index) {
                              return _c(
                                "v-btn",
                                {
                                  key: index,
                                  staticClass: "v-application elevation-2",
                                  attrs: {
                                    value: index,
                                    disabled: !_setup.isReadOnly,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                _setup.ValidationObserver,
                { ref: "obs" },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "masonry",
                        {
                          attrs: { cols: { default: 2, 1264: 1 }, gutter: 12 },
                        },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: { noBorders: !_setup.isEditTLMode },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Geldigheid")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Geldig vanaf",
                                          rules: "date_between|required",
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig vanaf",
                                                    errors: errors,
                                                    outlined:
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEditTL,
                                                    rules: "required",
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .valFrom,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "valFrom",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.valFrom",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Geldig tot",
                                          rules: `date_between|date_after:${_setup.selectedData.valFrom}`,
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig tot",
                                                    errors: errors,
                                                    outlined:
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEditTL,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .valTill,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "valTill",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.valTill",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: {
                                noBorders:
                                  !_setup.isEditDataMode &&
                                  !_setup.isEditTLMode,
                              },
                            },
                            [
                              _c("header", [_vm._v("Aanpassing")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Aanpassings omschrijving",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit &&
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Omschrijving",
                                                    "error-messages": errors,
                                                    outlined:
                                                      _setup.allowEdit ||
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEdit &&
                                                      !_setup.allowEditTL,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .modDescr,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "modDescr",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.modDescr",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_setup.isAddingMode
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Bewerker",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.selectedData.modBy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.selectedData,
                                                  "modBy",
                                                  $$v
                                                )
                                              },
                                              expression: "selectedData.modBy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_setup.isAddingMode
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Datum",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.modDateFormatted,
                                              callback: function ($$v) {
                                                _setup.modDateFormatted = $$v
                                              },
                                              expression: "modDateFormatted",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "masonry",
                        {
                          attrs: { cols: { default: 2, 1264: 1 }, gutter: 12 },
                        },
                        [
                          _setup.selectedRegisterId
                            ? _c(
                                "v-container",
                                {
                                  staticClass: "boxed",
                                  class: { noBorders: !_setup.isEditTLMode },
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c("header", [_vm._v("Details koppeling")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Gebruiksfactor",
                                              rules:
                                                "required|decimal:4|between:0,1",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-text-field", {
                                                        class: {
                                                          noBorders:
                                                            !_setup.allowEdit,
                                                        },
                                                        attrs: {
                                                          type: "number",
                                                          "hide-details":
                                                            "auto",
                                                          label:
                                                            "Gebruiksfactor",
                                                          "error-messages":
                                                            errors,
                                                          "persistent-placeholder":
                                                            "",
                                                          outlined:
                                                            _setup.allowEdit,
                                                          readonly:
                                                            !_setup.allowEdit,
                                                        },
                                                        model: {
                                                          value:
                                                            _setup.selectedData
                                                              .allocFact,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _setup.selectedData,
                                                              "allocFact",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "selectedData.allocFact",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2547847779
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "",
                                              "persistent-placeholder": "",
                                              label:
                                                "Paris proof score toekennings factor",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.register.ppsFactor,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.register,
                                                  "ppsFactor",
                                                  $$v
                                                )
                                              },
                                              expression: "register.ppsFactor",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "masonry",
                    { attrs: { cols: { default: 1 }, gutter: 12 } },
                    [
                      _setup.allDataLoaded && !_setup.selectedRegisterId
                        ? _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: { noBorders: !_setup.isEditTLMode },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Selecteer meter")]),
                              _c(_setup.RegisterOverview, {
                                attrs: { isSelector: true },
                                on: {
                                  "selected-register": _setup.selectedRegister,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("details-buttons", {
                    attrs: {
                      addMode: _setup.isAddingMode,
                      editMode: !_setup.isReadOnly,
                      allowSave: !_setup.isSaving,
                    },
                    on: {
                      edit: _setup.setEditMode,
                      "edit-tl": _setup.setEditTLMode,
                      "on-cancel": _setup.onCancel,
                      "add-new-detail": _setup.addNewDetail,
                      "on-cancel-add": _setup.onCancelAdd,
                      "save-detail": _setup.saveDetail,
                      "save-new-detail": _setup.saveNewDetail,
                    },
                  }),
                  _c(
                    "masonry",
                    { attrs: { cols: { default: 2, 1264: 1 }, gutter: 12 } },
                    [
                      _setup.selectedRegisterId
                        ? _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: { noBorders: !_setup.isEditTLMode },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Metergegevens")]),
                              _vm.addModus
                                ? _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c("v-col", { attrs: { cols: "3" } }),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "primary my-1",
                                              attrs: {
                                                dark: "",
                                                title: "UnSelectMeter",
                                              },
                                              on: {
                                                click: _setup.unSelectRegister,
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { dark: "", left: "" },
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-checkbox-marked-circle-outline"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " Selecteer andere meter "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("label", [_vm._v("Referentie:")]),
                                  ]),
                                  _c(
                                    "v-col",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_setup.register.reference) +
                                          " "
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            to: {
                                              name: "EU-Register",
                                              params: {
                                                registerId: _setup.register.id,
                                                addModus: false,
                                              },
                                            },
                                            icon: "",
                                            small: "",
                                            plain: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v(" mdi-link-variant ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("label", [_vm._v("Omschrijving:")]),
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_setup.register.description) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("label", [_vm._v("Type:")]),
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _setup.register.typeDescription
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("label", [_vm._v("Energietype:")]),
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _setup.register.energyTypeDescription
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("label", [_vm._v("Eenheid:")]),
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_setup.register.unitTypeName) +
                                        " (" +
                                        _vm._s(
                                          _setup.register.unitTypeDescription
                                        ) +
                                        ") "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("label", [_vm._v("Bron:")]),
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      " " + _vm._s(_setup.register.source) + " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("label", [_vm._v("Bronreferentie:")]),
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _setup.register.sourceReference
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("label", [_vm._v("Geldigheid:")]),
                                  ]),
                                  _c("v-col", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _setup.formatDateUtcD(
                                            _setup.register.validFrom
                                          )
                                        ) +
                                        " t/m " +
                                        _vm._s(
                                          _setup.formatDateUtcD(
                                            _setup.register.validTill
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _setup.selectedRegisterId
                        ? _c(
                            "v-container",
                            {
                              staticClass: "boxed",
                              class: { noBorders: !_setup.isEditTLMode },
                              attrs: { fluid: "" },
                            },
                            [
                              _c("header", [_vm._v("Verbruikgegevens")]),
                              _c(_setup.RegisterUsageYearlyOverview, {
                                attrs: {
                                  registerId: _setup.register.id,
                                  unitType:
                                    _setup.register.unitTypeName +
                                    " (" +
                                    _setup.register.unitTypeDescription +
                                    ")",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }